import React, { useState, useEffect } from "react";
import Turkey from "pages/turkey";
import Main from "pages/main/main";
import Emirates from "pages/emirates";
import Menu from "components/menu/Menu";
import PopupAbout from "components/popup/PopupAbout";
import PrepareData from "helpers/PrepareData";
import "library/library.scss";
import "App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [popupState, setPopupState] = useState(false);
  const [popupItem, setPopupItem] = useState(null);
  const [items, setItems] = useState([]);

  // useEffect(() => {
  //   fetch(
  //     "https://test.2106249-cs44578.twc1.net/api/houses"
  //   )

  //     .then((response) => response.json())
  //     .then((result) => {
  //       setItems(PrepareData(result));
  //     });
  // }, []);

  const openPopup = (item) => {
    setPopupState(true);
    setPopupItem(item);
  };

  const hidePopup = () => {
    setPopupState(false);
  };

  return (
    <div className="App">
      <div className="contentWrapper">
        <Router>
          <Routes>
            <Route
              element={<Emirates items={items} openPopup={openPopup} />}
              path="/"
            />
          </Routes>
        </Router>
      </div>
      <PopupAbout />
      {/* {popupItem && (
        <PopupAbout state={popupState} item={popupItem} hidePopup={hidePopup} />
      )} */}
    </div>
  );
}

export default App;

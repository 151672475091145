import PrepareDevelopers from "helpers/PrepareDevelopers";
import PrepareFoundations from "helpers/PrepareFoundations";
import PrepareWalls from "helpers/PrepareWalls";

const filtersApi = async () => {

  const fetchDevelopers = await fetch(
    `https://test.2106249-cs44578.twc1.net/api/house-developers`
  );

  const fetchFoundations = await fetch(
    `https://test.2106249-cs44578.twc1.net/api/fondations`
  );

  const fetchWalls = await fetch(
    `https://test.2106249-cs44578.twc1.net/api/wall-materials`
  );

  

  const developersData = await fetchDevelopers.json();
  const foundationsData = await fetchFoundations.json();
  const wallsData = await fetchWalls.json();
  const preparedDevelopers = PrepareDevelopers(developersData);
  const preparedFoundations = PrepareFoundations(foundationsData);
  const preparedWalls = PrepareWalls(wallsData);
  

  return {
    developers: preparedDevelopers,
    foundations: preparedFoundations,
    walls: preparedWalls
  };
};

export default filtersApi;

import styles from "./citySelect.module.scss";

export const CitySelect = () => {
  const items = [
    {
      name: "Алания",
      photo: "/img/turkey-cities/alanya.jpeg",
      price: "от 99,5 тыс до 1,75 млн $",
    },
    {
      name: "Анталия",
      photo: "/img/turkey-cities/antalya.jpg",
      price: "от 157 тыс до 843 тыс $",
    },
    {
      name: "Бодрум",
      photo: "/img/turkey-cities/bodrum.jpeg",
      price: "от 125 тыс до 5 млн $",
    },
    {
      name: "Измир",
      photo: "/img/turkey-cities/izmir.jpeg",
      price: "от 102 тыс до 854 тыс $",
    },
    {
      name: "Мерсин",
      photo: "/img/turkey-cities/mersin.jpg",
      price: "от 45 тыс до 248 тыс $",
    },
    {
      name: "Стамбул",
      photo: "/img/turkey-cities/istanbul.jpg",
      price: "от 426 тыс до 2,45 млн $",
    },
    {
      name: "Фетхие",
      photo: "/img/turkey-cities/fethie.jpeg",
      price: "от 116 тыс до 1 млн $",
    },
  ];

  return (
    <div className={styles.citySelect}>
      <h2>Объекты в разных городах Турции</h2>
      <div className={styles.citySelect__list}>
        {items.map((item) => (
          <div className={styles.citySelect__item}>
            <div
              className={styles.citySelectItem__img}
              style={{
                background: `url(${item.photo}) left top / cover no-repeat`,
              }}
            ></div>
            <div className={styles.citySelectItem__description}>
              <div className={styles.citySelectItem__name}>{item.name}</div>
              <div className={styles.citySelectItem__price}>{item.price}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

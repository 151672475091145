import React from "react";
import EstateItems from "components/estate/EstateItems";
import Tabs from "components/tabs/Tabs";
import Faq from "components/FAQ/Faq";
import styles from "./content.module.scss";
import Button from "../../library/UI/Button";
import Benefits from "components/benefits/Benefits";
import { CitySelect } from "components/city-select/citySelect";
import { ReactComponent as LogoIcon } from "../../library/icons/logo.svg";

function Content({ items, handler, pageName }) {

  return (
    <div className={styles.contentContainer}>
      <div className={styles.mainContainer}>
        <div className={styles.logo}>
          <LogoIcon />
        </div>
       
        <div className={styles.pageHeader}>
          <h1 className="heading heading_M">
            Объекты ИЖС
          </h1>
        </div>
        
        <a name="objects"></a>
        <EstateItems items={items} handler={handler} country={pageName} />
        {pageName === "Turkey" ? <CitySelect /> : ""}
        <Benefits page={pageName} />
        <Faq />
        <div className={styles.footer}>
          <a className={styles.footerLink} target="_blank" href="/files/policy.pdf">Политика конфиденциальности</a>
          <a className={styles.footerLink} target="_blank" href="/files/offer.pdf">Пользовательское соглашение</a>
        </div>
      </div>
    </div>
  );
}

export default Content;

import { React, useState, useEffect } from "react";
import styles from "./filters.module.scss";
import Select from "react-select";
import { fetchObjects, fetchFilters } from "store/objectSlice";
import { useSelector, useDispatch } from "react-redux";

const fondationOptions = [
  { value: 0, label: "Все типы фундаментов" },
  { value: "1", label: "Ленточный" },
  { value: "2", label: "Свайный" },
];

// const allDevelopers = [
//   { value: 0, label: "Все подрядчики", country: "Emirates" },
//   { value: "1", label: "Армада ПСК", country: "Emirates" },
//   { value: "2", label: "ОБЪЕКТ-А", country: "Emirates" },
//   { value: "3", label: "Domax", country: "Emirates" },
//   { value: "4", label: 'СК Новиград18', country: "Emirates" },
//   { value: "5", label: 'СК-2020', country: "Emirates" },
//   { value: "6", label: 'Дом Групп', country: "Emirates" },

// ];

// const wallOptions = [
//   { value: 0, label: "Все типы стен" },
//   { value: "1", label: "Кирпич" },
//   { value: "2", label: "Каркасный" },
//   { value: "3", label: "Бревно и брус" },
//   { value: "4", label: "Газобетон" },
// ];

const regionOptions = [
  { value: "1", label: "Москва и МО" },
  { value: "2", label: "Санкт-Петербург и ЛО" },
  { value: "3", label: "Краснодар" },
  { value: "4", label: "Ижевск" },
  { value: "5", label: "Новосибирск" },
];


function Filters({ onUpdateFilters, country }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFilters());
  }, [dispatch]);
  const filterValues = useSelector((state) => state.filters.items);


  const [stateDeveloper, setStateDeveloper] = useState({
    value: 0,
    label: "Все застройщики",
  });
  const [stateFoundation, setStateFoundation] = useState({
    value: 0,
    label: "Все типы фундамента",
  });
  const [stateWall, setStateWall] = useState({
    value: 0,
    label: "Все типы фундамента",
  });
  const [stateRegions, setStateRegions] = useState({});

  // const optionsDevelopers = allDevelopers.filter(
  //   (item) => item.country === country || item.label === "Все застройщики"
  // );

  let filtersValue = {
    foundation: stateFoundation,
    developer: stateDeveloper,
    wall: stateWall,
    regions: stateRegions
  };

  function setFilters(filterName, value) {
    filtersValue.filterName = value;
  }

  let handleChangeDeveloper = (selectedOption) => {
    setStateDeveloper(
      selectedOption,
    );
    onUpdateFilters({ foundation: stateFoundation, developer: selectedOption, wall: stateWall, regions: stateRegions });
  };

  let handleChangeFoundation = (selectedOption) => {
    setStateFoundation(
      selectedOption
    );
    onUpdateFilters({ foundation: selectedOption, developer: stateDeveloper, wall: stateWall, regions: stateRegions});  
  };

  let handleChangeWall = (selectedOption) => {
    setStateWall(
      selectedOption
    );
    onUpdateFilters({ foundation: stateFoundation, developer: stateDeveloper, wall: selectedOption, regions: stateRegions });  
  };

  let handleChangeRegion = (selectedOption) => {
    // console.log(selectedOption);
    setStateRegions(
      selectedOption
    )
    onUpdateFilters({ foundation: stateFoundation, developer: stateDeveloper, wall: stateWall, regions: selectedOption});  
  };

  let selectStyle = {
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      background: '#F3F4F8',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      flexWrap: "no-wrap",
    }),
  };

  let developerOptions;
  let wallOptions;

  if(filterValues.developers != undefined && filterValues.walls != undefined) {
    developerOptions = [{ value: 0, label: "Все подрядчики" }, ...filterValues.developers];
    wallOptions = [{ value: 0, label: "Все типы стен" }, ...filterValues.walls];
  }

  return (
    <div className={styles.filters}>
      
      <Select
        styles={selectStyle}
        defaultValue={{ label: "Все подрядчики", value: 0 }}
        options={developerOptions}
        onChange={handleChangeDeveloper}
        placeholder={<div>Застройщик</div>}
      />
      {/* <Select
        styles={selectStyle}
        defaultValue={{ label: "Все типы фундамента", value: 0 }}
        options={fondationOptions}
        onChange={handleChangeFoundation}
        placeholder={<div>Фундамент</div>}
      /> */}
      <Select
        styles={selectStyle}
        options={regionOptions}
        onChange={handleChangeRegion}
        isMulti = 'true'
        placeholder={<div>Все регионы</div>}
      />
      
      <Select
        styles={selectStyle}
        defaultValue={{ label: "Все типы стен", value: 0 }}
        options={wallOptions}
        onChange={handleChangeWall}
        placeholder={<div>Тип стен</div>}
      />
    </div>
  );
}

export default Filters;

import { configureStore } from "@reduxjs/toolkit";
import { objectReducer, filterReducer, popupReducer } from "./objectSlice";

export default configureStore({
  reducer: {
    objects: objectReducer,
    filters: filterReducer,
    popup: popupReducer,
  },
});

import { React, useState } from "react";
import EstateItem from "./EstateItem.jsx";
import Filters from "./Filters.jsx";
import styles from "./estateItems.module.scss";
import { ReactComponent as FilterError } from "./images/icon_empty-filter.svg";

function EstateItems({ items, handler, country }) {
  const [currentFilters, setCurrentFilters] = useState({
    developer: {
      value: 0,
      label: "Все",
    },
    foundation: {
      value: 0,
      label: "Все",
    },
    wall: {
      value: 0,
      label: "Все",
    },
    regions: {}
  });

  function onUpdateFilters(filtersValue) {
    setCurrentFilters(filtersValue);
  }

  let filterItems = items.filter((item) => {
    let regionQuery = true;
    if(currentFilters.regions.length > 0) {
      let findRegion = currentFilters.regions.find((regionItem) => {
        return item.regions.includes(regionItem.label);
      });
      if(findRegion === undefined) {
        regionQuery=false;
      }
    }

    let query = (currentFilters.developer.value == 0 || item.company == currentFilters.developer.label) && (currentFilters.foundation.value == 0 || item.foundation == currentFilters.foundation.label) && (currentFilters.wall.value == 0 || item.wall_material == currentFilters.wall.label) && regionQuery;

    return (query);
  });


  function showItems(items) {
    if (items.length > 0) {
      return (
        <div className={styles.objects}>
          {items.map((item, index) => (
            <EstateItem item={item} key={index} handler={handler} />
          ))}
        </div>
      );
    } else {
      return (
        <div className={styles.filterError}>
          <FilterError />
          <div className={styles.filterError__header}>
            Пока нет объектов,соответствующих фильтрам
          </div>
          <div className={styles.filterError__text}>
            Уже подключаем новых подрядчиков  <br />и будем рады рекомендациям: <br />
          </div>
          <div className={styles.filterError__text}>
            {/* Пишите Александру Страхову: <br /> */}
            <a className={styles.filterError__link} href="mailto:a.strakhov@ipoteka.center">a.strakhov@ipoteka.center</a>  <a className={styles.filterError__link}  href="https://t.me/alex9095">Телеграм</a>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={styles.estateItems}>
      <Filters onUpdateFilters={onUpdateFilters} country={country}></Filters>
      {showItems(filterItems)}
    </div>
  );
}

export default EstateItems;

import PrepareData from "helpers/PrepareData";

const objectsApi = async (country) => {
  const fetchObjects = await fetch(
    `https://test.2106249-cs44578.twc1.net/api/houses?populate=*&pagination[pageSize]=200`
  );

  const data = await fetchObjects.json();
  const preparedObjects = PrepareData(data);

  return preparedObjects;
};

export default objectsApi;
